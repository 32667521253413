import { BET_STATUSES, BET_TYPES, BONUS_FIELDS, BONUS_TYPES } from 'utils/constants';
import { convertExternalId, transformObjectKeys } from 'utils/utils';

export * from './player-bonuses';

export const deserializePagination = pagination => ({
  total: pagination.total,
  page: pagination.page,
  limit: pagination.limit,
  lastPage: pagination.last_page,
});

export function deserializeCmsPagination(pagination) {
  return {
    total: pagination.total,
    page: pagination.page,
    limit: pagination.pageSize,
    lastPage: pagination.pageCount,
  };
}

export const deserializeManager = m => ({
  email: m.email,
  firstName: m.first_name,
  id: m.id,
  lastName: m.last_name,
  phone: m.phone,
});

export const deserializeProject = p => ({
  feeGroups: p.fee_groups,
  key: p.key,
  managers: (p?.managers ?? []).map(deserializeManager),
  name: p.name,
  permissions: p.permissions,
});

export const deserializeSection = s => ({
  key: s.key,
  name: s.name,
  permissions: s.permissions,
});

export const deserializeUser = u => ({
  id: u.id,
  firstName: u.first_name,
  lastName: u.last_name,
  jobTitle: u.job_title,
  department: u.department,
  email: u.email,
  phone: u.phone,
  projects: u.projects,
  roles: u.roles,
  permissions: u.permissions,
  status: u.status,
  tz: u.tz || undefined,
});

export const deserializePlayer = (p, parentId) => ({
  globalId: p.global_id,
  mergedId: p.merged_id,
  email: p.email,
  platformId: p.platform_id,
  sportsbookId: p.sportsbook_id,
  project: p.project,
  countryCode: p.country_code,
  selectedCurrency: p.selected_currency,
  selectedLanguage: p.selected_language,
  affiliateTag: p.affiliate_tag,
  verified: p.verified,
  birthDate: p.birthdate,
  registeredAt: p.registered_at,
  groups: p.groups ?? [],
  gender: p.gender,
  ltdAutoUpdate: p.ltd_auto_update,
  ccfAutoUpdate: p.ccf_auto_update,
  hierarchy: parentId ? [parentId, p.global_id] : [p.global_id],
  balance: p.balance
    ? {
        totalEur: p.balance.total_eur,
        details: p.balance.details?.map(item => ({
          amount: item.amount,
          amountEur: item.amount_eur,
          currency: {
            code: item.currency?.code,
            subunits: item.currency?.subunits_to_unit,
          },
        })),
      }
    : undefined,
  ccf: p.ccf / 100,
  ltd: p.ltd,
  mergedBy: p.merged_by,
});

export const deserializeMergedPlayer = mergedPlayer => [
  deserializePlayer(mergedPlayer.primary),
  ...(mergedPlayer.secondary || []).map(p => deserializePlayer(p, mergedPlayer.primary.global_id)),
];

export const deserializeMergedPlayersList = data =>
  data.reduce((result, mergedPlayer) => [...result, ...deserializeMergedPlayer(mergedPlayer)], []);

const deserializeBonusOdd = b => ({
  odds: b.odds,
  outcomesCount: {
    max: b.outcomes_count.max,
    min: b.outcomes_count.min,
  },
});

const deserializeEventCondition = e => ({
  sportType: e.sport_type,
  sportId: e.sport_id,
  categoryId: e.category_id,
  tournamentId: e.tournament_id,
  eventId: e.event_id,
});

function deserializeMedia(media) {
  const cmsUrl = process.env.CMS_API_ORIGIN;

  return (
    media && {
      id: media.id,
      url: cmsUrl && media?.url?.startsWith('/') ? `${cmsUrl}${media.url}` : media.url,
      alt: media.alt,
      name: media.name,
    }
  );
}

function deserializeBonusCommonData(bonus) {
  return {
    author: deserializeUser(bonus.author),
    createdAt: bonus.created_at,
    id: bonus.id,
    [BONUS_FIELDS.ISSUE_PERIOD]: {
      from: bonus.issue_from,
      to: bonus.issue_to,
    },
    permissions: bonus.permissions,
    status: bonus.status,
    [BONUS_FIELDS.NAME]: bonus.display_name,
    [BONUS_FIELDS.ID]: bonus.name,
    [BONUS_FIELDS.COUNTRY_CODES]: bonus.country_codes ?? [],
    [BONUS_FIELDS.EVENT_CONDITIONS]: bonus.event_conditions?.map(deserializeEventCondition) ?? [],
    [BONUS_FIELDS.TRIGGER_NAME]: bonus.trigger_name,
    [BONUS_FIELDS.NOTIFY_BY_EMAIL]: bonus.notify_by_email,
    [BONUS_FIELDS.BANNERS]: {
      isEnabled: (bonus.banners?.desktop || bonus.banners?.mobile) ?? false,
      desktop: deserializeMedia(bonus.banners?.desktop),
      mobile: deserializeMedia(bonus.banners?.mobile),
    },
  };
}

function deserializeBetType(bonus) {
  if (bonus.selections_count_to === 1) {
    return BET_TYPES.SINGLE;
  }

  if (bonus.selections_count_from > 1 && !bonus.selections_count_to) {
    return BET_TYPES.COMBO;
  }

  return BET_TYPES.ALL;
}

const deserializeBonusTriggers = bonus =>
  Object.fromEntries((bonus.trigger_fields ?? []).map(({ name, value }) => [name, value]));

const deserializeDepositAmount = depositAmount => ({
  from: depositAmount.from,
  to: depositAmount.to,
  max: depositAmount.max,
  type: depositAmount.type,
  value: depositAmount.value,
  customAmounts: depositAmount.custom_amounts,
});

function deserializeTriggerFields(bonus) {
  const triggers = deserializeBonusTriggers(bonus);

  return {
    [BONUS_FIELDS.AFFILIATE_TAG]: triggers.affiliate_tag,
    [BONUS_FIELDS.PLAYER_ID]: triggers.player_id,
    [BONUS_FIELDS.TAGS]:
      triggers.except_tag_id?.length > 0 ? triggers.except_tag_id : triggers.tag_id ?? [],
    [BONUS_FIELDS.IS_TAGS_EXCLUDED]: triggers.except_tag_id?.length > 0,
    [BONUS_FIELDS.ONLY_VERIFIED]: triggers.only_verified,
    [BONUS_FIELDS.COUNTRIES]: triggers.countries,
    [BONUS_FIELDS.DAYS_AFTER_REGISTRATION]: triggers.deposit_days_after_registration,
    [BONUS_FIELDS.DEPOSIT_NUMBER]:
      triggers.only_deposit_number || triggers.only_deposit_number_after_registration,
    [BONUS_FIELDS.DEPOSIT_NUMBER_TYPE]: triggers.only_deposit_number
      ? Boolean(triggers.only_deposit_number_after_registration)
      : undefined,
    [BONUS_FIELDS.DEPOSIT_SUM]: triggers.deposit_sum,
    [BONUS_FIELDS.DEPOSIT_PERIOD]: triggers.deposit_from && {
      from: triggers.deposit_from,
      to: triggers.deposit_to,
    },
    [BONUS_FIELDS.PROMOCODE]: triggers.bonus_code,
    [BONUS_FIELDS.PROMOCODE_QUANTITY]: triggers.bonus_code_quantity,
    [BONUS_FIELDS.DEPOSIT_BONUS_SUM]: triggers.amounts_by_currency
      ? { ...triggers.amounts_by_currency, autoconvert: false }
      : {
          fiatMax: triggers.fiat_amounts?.[0].max,
          fiatAmounts: (triggers.fiat_amounts ?? []).map(deserializeDepositAmount),
          cryptoMax: triggers.crypto_amounts?.[0].max,
          cryptoAmounts: (triggers.crypto_amounts ?? []).map(deserializeDepositAmount),
          autoconvert: true,
        },
    [BONUS_FIELDS.AMB_BET_STATUS]: (triggers.bet_status ?? []).reduce(
      (result, status) => ({
        ...result,
        [status]: true,
      }),
      {}
    ),
    [BONUS_FIELDS.AMB_TRIGGER_COUNTRY_CODES]: triggers.events_countries ?? [],
    [BONUS_FIELDS.AMB_TRIGGER_EVENT_CONDITIONS]:
      triggers.events_details?.map(deserializeEventCondition) ?? [],
    [BONUS_FIELDS.AMB_BET_MIN_ODD]: triggers.bet_min_odds,
    [BONUS_FIELDS.AMB_BET_MIN_STAKE]: triggers.bet_min_stake,
    [BONUS_FIELDS.AMB_BET_MAX_STAKE]: triggers.bet_max_stake,
    [BONUS_FIELDS.AMB_BONUS_SUM]: (triggers.bet_ranges ?? []).map(range => ({
      totalBets: range.total_bets,
      quantity: range.uses_count,
      fiatAmount: range.bonus_fiat_amount,
      cryptoAmount: range.bonus_crypto_amount,
      customAmounts: range.custom_amounts,
    })),
    [BONUS_FIELDS.AMB_TRIGGER_BET_TYPE]: {
      single: triggers.bet_types?.types?.includes('single'),
      multi: triggers.bet_types?.types?.includes('multi'),
      system: triggers.bet_types?.types?.includes('system'),
      multiValue: triggers.bet_types?.multi_min_outcomes,
      systemValue: triggers.bet_types?.system_min_selections,
    },
    [BONUS_FIELDS.AMB_TRIGGER_EVENT_STATUS]: triggers.event_status,
    [BONUS_FIELDS.AMB_TRIGGER_EVENT_MARKET]: triggers.only_top_markets,
  };
}

const deserializeComboboost = comboboost => ({
  ...deserializeBonusCommonData(comboboost),
  bonusType: BONUS_TYPES.COMBOBOOST,
  [BONUS_FIELDS.SHOW_IN_PROMOTION]: comboboost.show_in_promotion ?? false,
  [BONUS_FIELDS.VALIDITY_PERIOD]: {
    from: comboboost.valid_from,
    to: comboboost.valid_to,
  },
  [BONUS_FIELDS.QUANTITY]: comboboost.uses_count,
  [BONUS_FIELDS.MATCH_STATUS]: comboboost.match_status ?? null,
  [BONUS_FIELDS.ONLY_TOP_MARKETS]: comboboost.only_top_markets,
  minimumOutcomeOdd: comboboost.min_odds,
  minimumSelectionOdd: comboboost.min_selection_odds,
  bonusOdds: comboboost.bonus_odds.map(deserializeBonusOdd),
  ...deserializeTriggerFields(comboboost),
});

const deserializeFreebet = freebet => ({
  ...deserializeBonusCommonData(freebet),
  bonusType: BONUS_TYPES.FREEBET,
  [BONUS_FIELDS.VALIDITY_PERIOD]: {
    from: freebet.valid_from,
    to: freebet.valid_to,
  },
  [BONUS_FIELDS.VALID_DAYS]: freebet.valid_days,
  [BONUS_FIELDS.QUANTITY]: freebet.uses_count,
  [BONUS_FIELDS.FREEBET_TYPE]: freebet.type,
  [BONUS_FIELDS.BET_TYPE]: deserializeBetType(freebet),
  [BONUS_FIELDS.SELECTION_FROM]: freebet.selections_count_from,
  [BONUS_FIELDS.MINIMUM_ODD]: freebet.min_odds,
  [BONUS_FIELDS.MAXIMUM_ODD]: freebet.max_odds,
  [BONUS_FIELDS.MATCH_STATUS]: freebet.match_status ?? null,
  [BONUS_FIELDS.ONLY_TOP_MARKETS]: freebet.only_top_markets,
  [BONUS_FIELDS.BOOST]: freebet.boost_data,
  [BONUS_FIELDS.FIAT_AMOUNT]: freebet.bonus_fiat_amount,
  [BONUS_FIELDS.CRYPTO_AMOUNT]: freebet.bonus_crypto_amount,
  [BONUS_FIELDS.CUSTOM_AMOUNT]: freebet.bonus_custom_amounts,
  hasChildren: freebet.has_child,
  [BONUS_FIELDS.AMB_SUM_CONFIGURATION]: {
    isFiatAmountActive: freebet.bonus_fiat_amount > 0,
    isCryptoAmountActive: freebet.bonus_crypto_amount > 0,
  },
  ...deserializeTriggerFields(freebet),
});

const deserializeHunting = hunting => ({
  ...deserializeBonusCommonData(hunting),
  bonusType: BONUS_TYPES.HUNTING,
  huntingType: hunting.type,
  [BONUS_FIELDS.SHOW_IN_PROMOTION]: hunting.show_in_promotion ?? false,
  [BONUS_FIELDS.MINIMUM_OUTCOME_ODD]: hunting.min_odds,
  [BONUS_FIELDS.ISSUE_POINTS]: hunting.farming_percent,
  [BONUS_FIELDS.ISSUE_FOR_SAME_EVENT]: !hunting.farm_once_per_event,
  [BONUS_FIELDS.FARMING_COUNTRY_CODES]: hunting.farming_country_codes ?? [],
  [BONUS_FIELDS.FARMING_EVENT_CONDITIONS]:
    hunting.farming_event_conditions?.map(deserializeEventCondition) ?? [],
  [BONUS_FIELDS.FARMING_DAYS]: hunting.farming_days,
  [BONUS_FIELDS.FREEBET_TYPE]: hunting.freebet.type,
  [BONUS_FIELDS.BET_TYPE]: deserializeBetType(hunting.freebet),
  [BONUS_FIELDS.SELECTION_FROM]: hunting.freebet.selections_count_from,
  [BONUS_FIELDS.MINIMUM_ODD]: hunting.freebet.min_odds,
  [BONUS_FIELDS.MAXIMUM_ODD]: hunting.freebet.max_odds,
  [BONUS_FIELDS.MATCH_STATUS]: hunting.freebet.match_status ?? null,
  [BONUS_FIELDS.ONLY_TOP_MARKETS]: hunting.freebet.only_top_markets,
  [BONUS_FIELDS.COUNTRY_CODES]: hunting.freebet?.country_codes ?? [],
  [BONUS_FIELDS.EVENT_CONDITIONS]:
    hunting.freebet?.event_conditions?.map(deserializeEventCondition) ?? [],
  [BONUS_FIELDS.VALIDITY_PERIOD]: {
    from: hunting.freebet.valid_from,
    to: hunting.freebet.valid_to,
  },
  [BONUS_FIELDS.VALID_DAYS]: hunting.freebet.valid_days,
  [BONUS_FIELDS.BOOST]: hunting.freebet.boost_data,
  [BONUS_FIELDS.FIAT_AMOUNT]: hunting.bonus_fiat_amount,
  [BONUS_FIELDS.CRYPTO_AMOUNT]: hunting.bonus_crypto_amount,
  ...deserializeTriggerFields(hunting),
  [BONUS_FIELDS.CUSTOM_AMOUNT]: hunting.bonus_custom_amounts,
});

const deserializeHuntingLootbox = lootbox => ({
  ...deserializeHunting(lootbox),
  [BONUS_FIELDS.LOOTBOX_BOOST]: lootbox.freebet.boost_data,
  bonusType: BONUS_TYPES.LOOTBOX,
});

const deserializeHuntingTournament = tournament => ({
  ...deserializeHunting(tournament),
  bonusType: BONUS_TYPES.TOURNAMENT,
  [BONUS_FIELDS.POOL_AMOUNT]: tournament.tournament_stats.initial_pool,
});

const BONUS_TYPE_TO_DESERIALIZE = {
  [BONUS_TYPES.COMBOBOOST]: deserializeComboboost,
  [BONUS_TYPES.FREEBET]: deserializeFreebet,
  [BONUS_TYPES.HUNTING]: deserializeHunting,
  [BONUS_TYPES.LOOTBOX]: deserializeHuntingLootbox,
  [BONUS_TYPES.TOURNAMENT]: deserializeHuntingTournament,
};

export const deserializeBonus = (bonusType, bonus) => {
  const deserializeBonusByType = BONUS_TYPE_TO_DESERIALIZE[bonusType];

  if (!deserializeBonusByType) {
    throw new Error(`Unexpected bonus type for deserialization: ${bonusType}`);
  }

  return transformObjectKeys(deserializeBonusByType(bonus));
};

export const deserializeStatistic = (s, index) => ({
  id: index,
  activePlayers: Number(s.active_players),
  arpu: s.arpu,
  avgBet: s.avg_bet ?? 0,
  betcashoutPercentage: s.betcashout_percentage ?? 0,
  betcashoutReal: s.betcashout_real ?? 0,
  betcashoutResult: s.betcashout_result,
  betsCount: Number(s.bets_count),
  betsSum: s.bets_sum,
  betType: s.bet_type,
  bonusBetsSum: s.bonus_bets_sum,
  date: s.date,
  device: s.customer_device,
  ggr: s.ggr,
  margin: s.margin ?? 0,
  newActivePlayers: Number(s.new_active_players),
  pendingBetsSum: s.pending_bets_sum,
  pendingBonusesBetsSum: s.pending_bonuses_bets_sum,
  potentialBonusWinSum: s.potential_bonus_win_sum,
  potentialWinSum: s.potential_win_sum,
  projectName: s.client_name,
  sportUrnId: s.sport_external_id,
  sportName: s.sport_name,
  sportNameWithId: s.sport_external_id
    ? `${s.sport_name} (${convertExternalId(s.sport_external_id)})`
    : s.sport_name,
  uniqUsedBonusesUserCount: Number(s.uniq_used_bonuses_user_count),
  usedBonusesBetsCount: Number(s.used_bonuses_bets_count),
  winningBonusesBetsCount: Number(s.winning_bonuses_bets_count),
  winningBonusesSum: s.winning_bonuses_sum,
  country: s.customer_country,
  currency: s.currency,
  stag: s.customer_s_tag,
  groupId: s.customer_group_id,
  jackpotWin: s.jackpot_win,
});

const deserializePaymentMethodMetric = p => ({
  paymentMethod: p.payment_method,
  depositsCount: Number(p.deposits_count),
  depositsSumEur: p.deposits_sum_eur,
  successCashoutsSumEur: p.success_cashouts_sum_eur,
  pendingCashoutsSumEur: p.pending_cashouts_sum_eur,
});

const deserializeNumber = n => (n != null ? Number(n) : n);

const deserializePaymentMethodByCurrency = p => ({
  paymentMethod: p.payment_method,
  currency: p.currency,
  depositsCount: deserializeNumber(p.deposits_count),
  depositsSum: deserializeNumber(p.deposits_sum),
  depositsSumEur: p.deposits_sum_eur,
  successCashoutsCount: deserializeNumber(p.success_cashouts_count),
  successCashoutsSum: deserializeNumber(p.success_cashouts_sum),
  successCashoutsSumEur: p.success_cashouts_sum_eur,
  pendingCashoutsCount: deserializeNumber(p.pending_cashouts_count),
  pendingCashoutsSumEur: p.pending_cashouts_sum_eur,
  pendingCashoutsSum: deserializeNumber(p.pending_cashouts_sum),
});

const deserializeBetsStatistics = (bs, index) => ({
  id: index,
  confirmedBetsCount: deserializeNumber(bs.confirmed_bets_count),
  rejectedBetsCount: deserializeNumber(bs.rejected_bets_count),
  rejectedBetsPercent: bs.rejected_bets_percent,
  cancelledBetsCount: deserializeNumber(bs.cancelled_bets_count),
  winningBetsPercent: bs.winning_bets_percent,
  winningBonusBetsPercent: bs.win_bonus_bets_percent,
  turnover: bs.turnover,
  pendingTurnover: bs.pending_bets_sum,
  pendingBonusTurnover: bs.pending_bonus_bets_sum,
  potentialWin: bs.potential_win_sum,
  calculatedBetsSum: bs.calculated_bets_sum,
  profit: bs.profit,
  profitPerBet: bs.profit_per_bet,
  margin: bs.margin,
  avgBet: bs.avg_bet,
  avgOdd: bs.avg_coefficient,
  stakeGamePeriod: bs.stake_game_period,
  stakeBetType: bs.stake_bet_type,
});

export const deserializePlayerStatistics = p => {
  const betCashout = p.general.bet_cashout?.split(' / ')?.map(Number);
  const firstConfirmedBet = p.general.first_confirmed_bet;
  const firstRejectedBet = p.general.first_rejected_bet;

  return {
    sbProfit: p.sb_profit,
    cs: p.casino_profit,
    depositsSum: p.deposits_sum_eur,
    betCashout: {
      count: betCashout[0],
      real: betCashout[1],
    },
    betsByDevice: p.general.bets_by_device?.map(b => ({
      device: b.device,
      count: Number(b.bets_count),
    })),
    mostBetsIp: p.general.most_bets_ip,
    ipBetsCount: p.general.ip_bets_count?.map(b => ({
      ip: b.ip,
      bets: Number(b.bets),
      countOfOtherAccountsUsed: Number(b.other_accounts_used),
    })),
    paymentMetricsByCurrency: p.payment_metrics_by_currency?.map(
      deserializePaymentMethodByCurrency
    ),
    winBets: p.general.win_bets_percent,
    winBonusBets: p.general.win_bonus_bets_percent,
    sbGiftSum: p.sb_gift_sum_eur,
    casinoGiftSum: p.casino_gift_sum_eur,
    gamePeriodRatio: {
      liveToTotalRatio: p.general.game_period_ratio?.[0]?.live_to_total_ratio,
      prematchToTotalRatio: p.general.game_period_ratio?.[0]?.prematch_to_total_ratio,
    },
    confirmedBets: {
      count: p.general.confirmed_bets_count,
      firstBet: firstConfirmedBet
        ? {
            acceptedAt: new Date(p.general.first_confirmed_bet.accepted_at),
            clientName: p.general.first_confirmed_bet.client_name,
          }
        : undefined,
    },
    rejectedBets: {
      count: p.general.rejected_bets_count,
      firstBet: firstRejectedBet
        ? {
            acceptedAt: new Date(p.general.first_rejected_bet.accepted_at),
            clientName: p.general.first_rejected_bet.client_name,
          }
        : undefined,
    },
    lastThreePaymentMethods: p.last_3_payment_methods,
    successCashoutsSum: p.success_cashouts_sum_eur,
    pendingCashoutsSum: p.pending_cashouts_sum_eur,
    turnover: Number(p.general.turnover),
    pendingTurnover: p.pending_bets_sum,
    potentialWin: p.potential_win_sum,
    avgOdds: p.avg_coefficient,
    avgBet: p.avg_bet,
    winningBonuses: {
      sum: p.general.winning_bonuses_sum,
      toGgr: p.general.winning_bonuses_to_ggr,
    },
    paymentMethodsMetrics: p.payment_method_metrics?.map(deserializePaymentMethodMetric),
    paymentMethodsByCurrency: p.payment_methods_by_currency
      ?.map(deserializePaymentMethodByCurrency)
      ?.reduce(
        (acc, item) => ({
          ...acc,
          [item.paymentMethod]: [...(acc[item.paymentMethod] || []), item],
        }),
        {}
      ),
    betsRatio: {
      casino: p.general.bets_ratio?.casino_percentage,
      sb: p.general.bets_ratio?.sb_percentage,
    },
    margin: p.general?.margin,
    betsByGamePeriod: p.by_game_period?.map(deserializeBetsStatistics),
    betsByBetType: p.by_bet_type?.map(deserializeBetsStatistics),
    betsByBetTypeGamePeriod: p.by_bet_type_game_period?.map(deserializeBetsStatistics),
  };
};

const deserializeStatisticByHour = s => ({
  betsSum: s.bets_sum,
  ggr: s.ggr,
  date: s.hour,
  margin: s.margin ?? 0,
});

const deserializeStatisticByDate = s => ({
  betsSum: s.bets_sum ?? null,
  ggr: s.ggr ?? null,
  date: s.time,
  margin: s.margin ?? 0,
});

export const deserializeMetricsByHour = rawData => {
  // Reports API returns { hour: 0, ... } as first element if it's present.
  // We must move it to the end because it is related to (23:00, 00:00] period:)
  return [
    ...rawData.filter(item => item.hour !== 0).map(deserializeStatisticByHour),
    ...rawData.filter(item => item.hour === 0).map(deserializeStatisticByHour),
  ];
};

export const deserializeMetricsByDate = rawData => {
  return rawData.map(deserializeStatisticByDate);
};

const deserializeSportType = s => ({
  sportType: s,
});

export const deserializeSportTypes = sportTypes => sportTypes.map(deserializeSportType);

const deserializeSport = s => ({
  sportId: s.id,
  sportExternalId: s.external_id,
  sportName: s.name,
  sportNameWithId: `${s.name} (${convertExternalId(s.external_id)})`,
  sportType: s.type,
});

export const deserializeSports = sports => sports.map(deserializeSport);

const deserializeTurfsportSport = s => {
  return {
    sportId: s.id,
    sportExternalId: s.external_id,
    sportName: s.name,
    sportNameWithId: `${s.name} (${convertExternalId(s.external_id)})`,
  };
};

export const deserializeTurfSportSports = sports => sports.map(deserializeTurfsportSport);

const deserializeCategory = c => ({
  categoryId: c.id,
  categoryExternalId: c.external_id,
  categoryName: c.name,
  categoryNameWithId: `${c.name} (${convertExternalId(c.external_id)})`,
  ...deserializeSport(c.sport),
});

export const deserializeCategories = categories => categories.map(deserializeCategory);

const deserializeTurfsportCategory = c => ({
  categoryId: c.id,
  categoryExternalId: c.external_id,
  categoryName: c.name,
  categoryNameWithId: `${c.name} (${convertExternalId(c.external_id)})`,
});

export const deserializeTurfsportCategories = categories =>
  categories.map(deserializeTurfsportCategory);

const deserializeTournament = t => ({
  tournamentId: t.id,
  tournamentExternalId: t.external_id,
  tournamentName: t.name,
  tournamentNameWithId: `${t.name} (${convertExternalId(t.external_id)})`,
  tournamentProvider: t.provider,
  ...deserializeCategory(t.category),
});

export const deserializeTournaments = tournaments => tournaments.map(deserializeTournament);

const deserializeTurfsportTournament = t => ({
  tournamentId: t.id,
  tournamentExternalId: t.external_id,
  tournamentName: t.name,
  tournamentNameWithId: `${t.name} (${convertExternalId(t.external_id)})`,
});

export const deserializeTurfsportTournaments = tournaments =>
  tournaments.map(deserializeTurfsportTournament);

export const deserializeEvents = events =>
  events.map(e => ({
    eventId: e.id,
    eventExternalId: e.external_id,
    eventName: e.name,
    eventNameWithId: `${e.name} (${convertExternalId(e.external_id)})`,
    eventProvider: e.provider,
    ...deserializeTournament(e.tournament),
  }));

export const deserializeTurfsportEvents = events =>
  events.map(e => ({
    eventId: e.id,
    eventExternalId: e.external_id,
    eventName: e.name,
    eventNameWithId: `${e.name} (${convertExternalId(e.external_id)})`,
    startTime: e.start_time,
    venue: e.venue,
  }));

export const deserializeEventCountries = eventCountries =>
  eventCountries.map(country => ({
    cca2: country.cca2,
    code: country.cca3,
    name: country.commonName,
  }));

export const deserializeCountries = countries =>
  countries.map(country => ({
    cca2: country.cca2,
    code: country.cca3,
    name: country.common_name,
    officialName: country.official_name,
  }));

export const deserializeTopTournament = tt => ({
  ggr: tt.ggr,
  tournamentExternalId: tt.tournament_external_id,
  tournamentName: tt.tournament_name,
  tournamentNameWithId: `${tt.tournament_name} (${convertExternalId(tt.tournament_external_id)})`,
});

export const deserializeTopEvent = te => ({
  ggr: te.ggr,
  eventExternalId: te.event_external_id,
  eventName: `${te.team_home_name} - ${te.team_away_name}`,
  eventNameWithId: `${te.team_home_name} - ${te.team_away_name} (${convertExternalId(
    te.event_external_id
  )})`,
});

export const deserializeTopPlayer = tp => ({
  ggr: tp.ggr,
  clientName: tp.client_name,
  sportsbookPlayerId: tp.customer_id,
  platformPlayerId: tp.customer_player_id,
});

export function deserializeTopMetrics(metrics, deserializeMetric) {
  return {
    topLoss: metrics.top_loss.map(deserializeMetric),
    topProfit: metrics.top_profit.map(deserializeMetric),
  };
}

export function deserializeRole(role) {
  return {
    key: role.key,
    name: role.name,
    parentKey: role.parent_key,
    permissions: role.permissions,
    sections: (role.sections || []).map(deserializeSection),
  };
}

export function deserializeDraft(draft) {
  return {
    id: draft.id,
    authorId: draft.author_id,
    createdAt: draft.created_at,
    name: draft.name,
    permissions: draft.permissions,
    project: deserializeProject(draft.project),
    type: draft.type,
    updatedAt: draft.updated_at,
    data: draft.data,
  };
}

export function deserializeConflictRole(role) {
  return {
    key: role.role_key,
    name: role.role_name,
    users: (role.users ?? []).map(deserializeUser),
  };
}

const deserializeLocale = locale => ({
  code: locale.code,
  default: locale.default,
  elasticAnalyzer: locale.elastic_analyzer,
  englishName: locale.english_name,
  nativeName: locale.native_name,
});

export function deserializeProjectSettings(settings) {
  return {
    locales: settings.locales.map(deserializeLocale),
    permissions: settings.permissions,
    sportTypeByProvider: settings.sport_type_by_provider,
    isHorseRacingEnabled: settings.additional_providers?.horse_racing?.enabled,
    isLotteryEnabled: settings.additional_providers?.lotto?.enabled,
    isVideoEnabled: settings.video_settings?.enabled,
    videoProviders: settings.video_settings?.providers,
    isValuedatorEnabled: settings.valuedator_settings?.enabled,
    isVipBetEnabled: settings.vip_bet_settings.enabled,
    vipMinStake: settings.vip_bet_settings.min_stake_eur,
    vipMinPotentialWin: settings.vip_bet_settings.min_potential_win_eur,
    isVipBetAutoRejectEnabled: settings.vip_bet_settings.auto_reject?.enabled,
    vipBetAutoRejectLiveSeconds: settings.vip_bet_settings.auto_reject?.live,
    vipBetAutoRejectPrematchSeconds: settings.vip_bet_settings.auto_reject?.prematch,
    multiBetsAvailableByProvider: settings.multi_bet_settings?.available_by_provider,
  };
}

export function deserializeJackpot(jackpot) {
  return {
    amount: jackpot.amount,
    amountInEur: jackpot.amount_eur,
    betId: jackpot.bet_id,
    currency: jackpot.currency,
    currencySubunits: jackpot.currency_subunits,
    id: jackpot.hit_id,
    jackpotId: jackpot.jackpot_id,
    name: jackpot.jackpot_name,
    email: jackpot.user_email,
    platformId: jackpot.user_platform_id,
    sportsbookId: jackpot.user_sportsbook_id,
    status: jackpot.status,
    project: jackpot.project,
    processedBy: jackpot.processed_by,
    processedAt: jackpot.processed_at,
    paidAt: jackpot.paid_at,
    createdAt: jackpot.created_at,
  };
}

export function deserializeAuditLog(log) {
  return {
    id: log.id,
    objectId: log.object_id,
    project: log.project,
    action: log.action,
    actionAt: log.action_at,
    object: log.object,
    userEmail: log.user_email,
    userRole: log.user_role,
    ip: log.ip,
    ipCountry: log.ip_country,
    ipCity: log.ip_city,
    status: log.status,
  };
}

export function deserializeAuditLogObjectStates(states) {
  return {
    before: states.object_before,
    after: states.object_after,
  };
}

export const deserializeCurrencies = currencies =>
  currencies.map(currency => ({
    code: currency.code,
    isFiat: currency.is_fiat,
    name: currency.name,
    subunits: currency.subunits_to_unit,
  }));

export function deserializeJackpotTeam(jackpotTeam) {
  return {
    name: jackpotTeam.name,
    id: jackpotTeam.id,
    project: jackpotTeam.project,
    status: jackpotTeam.status,
    updatedAt: jackpotTeam.updated_at,
    author: deserializeUser(jackpotTeam.author),
    betType: jackpotTeam.bet.bet_type,
    matchStatus: jackpotTeam.bet.match_status,
    onlyTopMarkets: jackpotTeam.bet.only_top_markets,
    minimumOdd: jackpotTeam.bet.min_odds,
    maximumOdd: jackpotTeam.bet.max_odds,
    minStake: jackpotTeam.bet.min_stake,
    maxStake: jackpotTeam.bet.max_stake,
    createdAt: jackpotTeam.created_at,
    events: jackpotTeam.events?.map(deserializeEventCondition) ?? [],
    filterType: jackpotTeam.filter.type,
    tags: jackpotTeam.filter.fields.tag_id,
    isTagsExcluded: jackpotTeam.filter.fields.exclude,
  };
}

function deserializeScore(s) {
  return s
    ? {
        home: s.home,
        away: s.away,
      }
    : undefined;
}

export function deserializeBetSelection(s) {
  return {
    id: s.id,
    odds: s.odds,
    outcomeName: s.outcome.name,
    sportKey: s.event.tournament.category.sport.key,
    sportName: s.event.tournament.category.sport.name,
    sportId: s.event.tournament.category.sport.external_id,
    categoryName: s.event.tournament.category.name,
    categoryId: s.event.tournament.category.external_id,
    tournamentName: s.event.tournament.name,
    tournamentId: s.event.tournament.external_id,
    marketName: s.market.name,
    startTime: s.event.start_time,
    score: deserializeScore(s.score),
    producer: s.producer,
    eventName: s.event.name,
    eventId: s.event.external_id,
    status: s.status,
    avgLine: {
      value: s.avg_line?.ev,
      valuedatorResult: s.avg_line?.valuedator_result,
      bookmakers: s.avg_line?.bookmakers?.map(bookmaker => ({
        name: bookmaker?.name,
        odds: bookmaker?.odds,
      })),
    },
  };
}

export function deserializeSportsbookBet(bet) {
  return {
    id: bet.id,
    type: bet.type,
    odds: bet.odds,
    createdAt: bet.created_at,
    settledAt: bet.settled_at,
    isVip: bet.is_vip,
    selections: bet.selections?.map(deserializeBetSelection),
    currency: bet.currency,
    stake:
      bet.bonus?.type === BONUS_TYPES.FREEBET_ALL_WIN ||
      bet.bonus?.type === BONUS_TYPES.FREEBET_ONLY_WIN
        ? bet.bonus.amount
        : bet.stake,
    stakeEur:
      bet.bonus?.type === BONUS_TYPES.FREEBET_ALL_WIN ||
      bet.bonus?.type === BONUS_TYPES.FREEBET_ONLY_WIN
        ? bet.bonus.amount_eur
        : bet.stake_eur,
    potentialWin: bet.amounts?.potential_win,
    potentialWinEur: bet.amounts?.potential_win_eur,
    potentialBonusWin: bet.amounts?.potential_bonus,
    potentialBonusWinEur: bet.amounts?.potential_bonus_eur,
    playerPlatformId: bet.player.platform_id,
    playerSportsBookId: bet.player.sportsbook_id,
    playerEmail: bet.player.email,
    countryCode: bet.player.country,
    ip: bet.ip,
    status: bet.status,
    isManuallyProcessing: bet.manual_processing,
    result: bet.result,
    systemInfo: {
      winCount: bet.system.win_count,
      totalCount: bet.system.total_count,
    },
    betSource: bet.bet_source,
    win:
      bet.amounts?.to_cashout > 0 && bet.status === BET_STATUSES.CASHED_OUT
        ? bet.amounts?.to_cashout
        : bet.amounts?.win,
    winEur:
      bet.amounts?.to_cashout_eur > 0 && bet.status === BET_STATUSES.CASHED_OUT
        ? bet.amounts?.to_cashout_eur
        : bet.amounts?.win_eur,
    bonusWin: bet?.amounts?.bonus,
    bonusWinEur: bet.amounts?.bonus_eur,
    profit: bet.profit,
    profitEur: bet.profit_eur,
    bonusId: bet.bonus?.id,
    bonusType: bet.bonus?.type,
    bonusOdds: bet.bonus?.odds,
    valuedatorResult: bet.valuedator_result,
    jackpots: bet?.jackpots?.map(jackpot => ({
      id: jackpot.id,
      type: jackpot.type,
    })),
  };
}

function deserializeTurfsportBetSelection(s) {
  return {
    id: s.id,
    odds: s.odds,
    outcomeName: s.outcome.name,
    sportKey: s.event.sport.slug,
    sportName: s.event.sport.name,
    sportId: s.event.sport.id,
    sportExternalId: s.event.sport.external_id,
    categoryName: s.event.category.name,
    categoryId: s.event.category.id,
    categoryExternalId: s.event.category.external_id,
    tournamentName: s.event.tournament.name,
    tournamentExternalId: s.event.tournament.external_id,
    tournamentId: s.event.tournament.id,
    marketName: s.market.name,
    startTime: s.event.start_time,
    producer: s.event_status,
    eventName: s.event.name,
    eventExternalId: s.event.external_id,
    result: s.status,
  };
}

export function deserializeTurfsportBet(bet) {
  return {
    id: bet.id,
    ticketId: bet.ticket_id,
    type: bet.type,
    odds: bet.odds,
    createdAt: bet.created_at,
    settledAt: bet.settled_at,
    selections: bet.selections?.map(deserializeTurfsportBetSelection),
    currency: bet.currency,
    stake: bet.stake,
    stakeEur: bet.stake_eur,
    potentialWin: bet.amounts?.potential_win,
    potentialWinEur: bet.amounts?.potential_win_eur,
    playerPlatformId: bet.player.platform_id,
    playerSportsBookId: bet.player.id,
    playerEmail: bet.player.email,
    countryCode: bet.player.country_code,
    ip: bet.ip,
    result: bet.result,
    betSource: bet.bet_source,
    win: bet.amounts?.win,
    winEur: bet.amounts?.win_eur,
    profit: bet.amounts?.profit,
    profitEur: bet.amounts?.profit_eur,
    tax: bet.amounts?.tax,
  };
}

export function deserializeProjectMinStake(p) {
  return {
    ...p.min_stake_by_currency,
  };
}

export function deserializeFolder(folder) {
  return {
    id: folder.id,
    name: folder.name,
  };
}

export function deserializeFiles(files) {
  return (files ?? []).map(file => ({
    id: file.id,
    url: file.url,
    alt: file.alternativeText,
    name: file.name,
    extension: file.ext,
    size: file.size,
  }));
}

export function deserializeProviders(providers) {
  return (providers ?? []).map(provider => ({
    key: provider.key,
    sportTypes: provider.sport_types,
  }));
}

export function deserializePlayersTags(rawData) {
  return {
    data: (rawData?.data ?? []).map(tag => ({
      id: tag.id,
      name: tag.name,
      project: tag.project,
    })),
    pagination: deserializePagination(rawData?.pagination),
  };
}

export function deserializeAffilkaTags(rawData) {
  return {
    data: (rawData?.data ?? []).map(tag => ({ core: tag.core })),
    pagination: deserializePagination(rawData?.pagination),
  };
}

export function deserializeBonusStatisticsGeneral(data, index) {
  return {
    id: index,
    bonusType: data.bonus_type,
    bonusIssued: data.bonus_issued,
    bonusUsed: data.bonus_used,
    bonusConversion: data.bonus_conversation,
    turnover: data.turnover,
    ggr: data.ggr,
    winnings: data.winnings,
    margin: data.margin,
  };
}

function getCcfLtdDirection(item, index, array) {
  if (index === array.length - 1 || item.value === array[index + 1].value) return null;

  return item.value > array[index + 1].value ? 'up' : 'down';
}

export function deserializeCcfHistoryItem(item, index, array) {
  return {
    id: item.created_at,
    comment: item.comment,
    createdAt: item.created_at,
    globalId: item.global_id,
    responsible: item.responsible_email,
    ccf: item.value / 100,
    autoUpdate: item.auto_update,
    direction: getCcfLtdDirection(item, index, array),
  };
}

export function deserializeLtdHistoryItem(item, index, array) {
  return {
    id: item.created_at,
    comment: item.comment,
    createdAt: item.created_at,
    globalId: item.global_id,
    responsible: item.responsible_email,
    ltd: item.value,
    autoUpdate: item.auto_update,
    direction: getCcfLtdDirection(item, index, array),
  };
}

export function deserializeFreebetGeneralStatisticsByBonusId(data, index) {
  return {
    id: index,
    bonusId: data.bonus_id,
    bonusType: data.bonus_type,
    trigger: data.trigger,
    promoCode: data.promo_code,
    createdAtDate: data.created_at_date,
    expiredAtDate: data.expired_at_date,
    platformGroup: data.platform_group,
    issuedNumber: data.issued_number,
    issuedAmount: data.issued_amount,
    issuedPlayers: data.issued_players,
    activatedNumber: data.activated_number,
    activatedNumberRate: data.activated_number_rate,
    activatedAmount: data.activated_amount,
    activatedAmountRate: data.activated_amount_rate,
    activatedPlayers: data.activated_players,
    activatedPlayersRate: data.activated_players_rate,
    usedNumber: data.used_number,
    usedNumberRate: data.used_number_rate,
    usedAmount: data.used_amount,
    usedAmountRate: data.used_amount_rate,
    usedPlayers: data.used_players,
    usedPlayersRate: data.used_players_rate,
    payoutsNumber: data.payouts_number,
    payoutsNumberRate: data.payouts_number_rate,
    payoutsAmount: data.payouts_amount,
    payoutsAmountRate: data.payouts_amount_rate,
    payoutsPlayers: data.payouts_players,
    payoutsPlayersRate: data.payouts_players_rate,
    avgBet: data.avg_bet,
    avgOdds: data.avg_odds,
  };
}

export function deserializeFreebetGeneralStatisticsByBonusType(data, index) {
  return {
    id: index,
    bonusType: data.bonus_type,
    issuedNumber: data.issued_number,
    issuedNumberRate: data.issued_number_rate,
    issuedAmount: data.issued_amount,
    issuedAmountRate: data.issued_amount_rate,
    activatedNumber: data.activated_number,
    activatedNumberRate: data.activated_number_rate,
    activatedAmount: data.activated_amount,
    activatedAmountRate: data.activated_amount_rate,
    usedNumber: data.used_number,
    usedNumberRate: data.used_number_rate,
    usedAmount: data.used_amount,
    usedAmountRate: data.used_amount_rate,
    payoutsNumber: data.payouts_number,
    payoutsNumberRate: data.payouts_number_rate,
    payoutsAmount: data.payouts_amount,
    payoutsAmountRate: data.payouts_amount_rate,
    avgBet: data.avg_bet,
    avgOdds: data.avg_odds,
  };
}

export function deserializeBonusStatisticsBooster(data) {
  return {
    multiplier: data.multiplier,
    probability: data.probability,
    players: data.players,
    initialFreebetNumber: data.initial_freebet_number,
    initialFreebetAmount: data.initial_freebet_amount,
    finalFreebetNumber: data.final_freebet_number,
    finalFreebetAmount: data.final_freebet_amount,
    payouts: data.payouts,
    margin: data.margin,
  };
}

export function deserializeReport(report) {
  return {
    id: report.id,
    fileName: report.filename,
    createdAt: report.created_at,
    expiredAt: report.expired_at,
    status: report.status,
  };
}

export function deserializeEvent(event) {
  return {
    eventId: String(event.event_id),
    startTime: event.start_time,
    eventName: event.event_name,
    sport: event.sport,
    category: event.category,
    tournament: event.tournament,
    firstIssuedBetTime: event.first_issued_bet_time,
    firstBetId: event.first_bet_id,
    lastIssuedBetTime: event.last_issued_bet_time,
    lastBetId: event.last_bet_id,
    noMoreBetsTime: event.no_more_bets_time,
    resultProcessed: event.result_processed,
    revisedOfficialCardedTime: event.revised_official_carded_time,
    eventChangesReason: event.event_changes_reason,
  };
}
