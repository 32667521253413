import { BONUS_TYPES } from 'utils/constants';
import { transformObjectKeys } from 'utils/utils';

const deserializeBonusCommonData = bonus => ({
  id: bonus.id,
  name: bonus.name,
  displayName: bonus.display_name,
  issuedAt: bonus.issued_at,
  createdAt: bonus.created_at,
  expiredAt: bonus.expired_at,
  status: bonus.status,
});

const deserializePlayerComboboost = comboboost => ({
  ...deserializeBonusCommonData(comboboost),
  timesUsed: comboboost.uses_count,
  timesToUse:
    comboboost.max_uses_count > 0 ? comboboost.max_uses_count - (comboboost.uses_count || 0) : null,
});

const deserializePlayerFreebet = freebet => ({
  ...deserializeBonusCommonData(freebet),
  activatedAt: freebet.activated_at,
  currency: freebet.currency,
  freebetType: freebet.type,
  timesUsed: freebet.uses_count,
  timesToUse: freebet.max_uses_count > 0 ? freebet.max_uses_count - (freebet.uses_count || 0) : 0,
  amount: freebet.amount / freebet.currency_subunits,
  farmedFromName: freebet.farmed_from?.name,
  farmedFromType: freebet.farmed_from?.type,
  boostMultiplier: freebet.boost_multiplier,
});

const deserializePlayerHunting = hunting => ({
  ...deserializeBonusCommonData(hunting),
  activatedAt: hunting.activated_at,
  currency: hunting.currency,
  initialAmount: hunting.initial_amount / hunting.currency_subunits,
  currentAmount: hunting.current_amount / hunting.currency_subunits,
  farmingEnd: hunting.farming_end,
});

const deserializePlayerHuntingLootbox = lootbox => ({
  ...deserializeBonusCommonData(lootbox),
  activatedAt: lootbox.activated_at,
  currency: lootbox.currency,
  initialAmount: lootbox.initial_amount / lootbox.currency_subunits,
  currentAmount: lootbox.current_amount / lootbox.currency_subunits,
  farmingEnd: lootbox.farming_end,
});

const deserializePlayerHuntingTournament = tournament => ({
  ...deserializeBonusCommonData(tournament),
  activatedAt: tournament.activated_at,
  currency: tournament.currency,
  initialAmount: tournament.initial_amount / tournament.currency_subunits,
  currentAmount: tournament.current_amount / tournament.currency_subunits,
  farmingEnd: tournament.farming_end,
});

const BONUS_TYPE_TO_DESERIALIZE = {
  [BONUS_TYPES.COMBOBOOST]: deserializePlayerComboboost,
  [BONUS_TYPES.FREEBET]: deserializePlayerFreebet,
  [BONUS_TYPES.HUNTING]: deserializePlayerHunting,
  [BONUS_TYPES.LOOTBOX]: deserializePlayerHuntingLootbox,
  [BONUS_TYPES.TOURNAMENT]: deserializePlayerHuntingTournament,
};

export const deserializePlayerBonus = (bonusType, bonus) => {
  const deserializeBonusByType = BONUS_TYPE_TO_DESERIALIZE[bonusType];

  if (!deserializeBonusByType) {
    throw new Error(`Unexpected player bonus type for deserialization: ${bonusType}`);
  }

  return transformObjectKeys(deserializeBonusByType(bonus));
};
