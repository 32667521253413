import PropTypes from 'prop-types';

import { VALUEDATOR_RESULT, BET_SOURCE_TYPE, BET_RESULTS } from 'templates/bets/constants';
import { BET_RESULTS as TURFSPORT_BET_RESULTS } from 'templates/bets/turfsport-bets/constants';
import {
  BET_SELECTION_STATUSES,
  BET_STATUSES,
  BET_TYPES,
  BONUS_EVENTS_TYPES,
  BONUS_TYPES,
  DATA_PROVIDERS,
  DEPOSIT_SUM_TYPES,
  GTM_EVENT_CATEGORIES,
  GTM_EVENT_PAGES,
  HUNTING_TYPES,
  PERMISSIONS,
  PRODUCER_TYPE,
  REPORT_STATUSES,
  SPORT_TYPES,
  USER_STATUSES,
} from 'utils/constants';

const PermissionType = PropTypes.oneOf(Object.values(PERMISSIONS));
export const BonusTypeType = PropTypes.oneOf(Object.values(BONUS_TYPES));
export const UserStatusType = PropTypes.oneOf(Object.values(USER_STATUSES));
export const BonusEventType = PropTypes.oneOf(Object.values(BONUS_EVENTS_TYPES));
export const BetTypeType = PropTypes.oneOf(Object.values(BET_TYPES));
export const BetSelectionStatusType = PropTypes.oneOf(Object.values(BET_SELECTION_STATUSES));
export const BetStatusType = PropTypes.oneOf(Object.values(BET_STATUSES));
export const BetResultType = PropTypes.oneOf(Object.values(BET_RESULTS));
export const TursportBetResultType = PropTypes.oneOf(Object.values(TURFSPORT_BET_RESULTS));
export const ProducerType = PropTypes.oneOf(Object.values(PRODUCER_TYPE));
export const SportType = PropTypes.oneOf(Object.values(SPORT_TYPES));
export const BetSourceType = PropTypes.oneOf(Object.values(BET_SOURCE_TYPE));
export const ValuedatorResultType = PropTypes.oneOf(Object.values(VALUEDATOR_RESULT));

export const PaginationType = PropTypes.exact({
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
});

export const TableRowType = PropTypes.exact({
  cells: PropTypes.arrayOf(PropTypes.node).isRequired,
  rowData: PropTypes.shape(),
});

export const UserType = PropTypes.exact({
  department: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  jobTitle: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PermissionType).isRequired,
  phone: PropTypes.string,
  projects: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  roles: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  status: UserStatusType.isRequired,
  tz: PropTypes.string,
});

const bonusOddType = PropTypes.exact({
  odds: PropTypes.number.isRequired,
  outcomesCount: PropTypes.exact({
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
  }),
});

const DisplayNameType = PropTypes.objectOf(PropTypes.string);

export const EventConditionType = PropTypes.exact({
  id: PropTypes.string,
  sportType: PropTypes.string,
  sportId: PropTypes.number,
  categoryId: PropTypes.number,
  tournamentId: PropTypes.number,
  eventId: PropTypes.number,
});

const DatePeriodType = PropTypes.exact({
  from: PropTypes.string.isRequired,
  to: PropTypes.string,
});

export const BoostItemType = PropTypes.exact({
  multiplier: PropTypes.number.isRequired,
  probability: PropTypes.number.isRequired,
});

export const BonusSumType = PropTypes.exact({
  fiatAmount: PropTypes.number,
  cryptoAmount: PropTypes.number,
  customAmount: PropTypes.object,
});

const DepositSumType = PropTypes.oneOf(Object.values(DEPOSIT_SUM_TYPES));

export const DepositSumRangeType = PropTypes.exact({
  from: PropTypes.number,
  to: PropTypes.number,
  max: PropTypes.number,
  type: DepositSumType,
  value: PropTypes.number,
  customAmounts: PropTypes.objectOf(PropTypes.number),
});

export const DepositBonusSumType = PropTypes.shape({
  fiatMax: PropTypes.number,
  fiatAmounts: PropTypes.arrayOf(DepositSumRangeType),
  cryptoMax: PropTypes.number,
  cryptoAmounts: PropTypes.arrayOf(DepositSumRangeType),
});

export const AfterMakingBetsType = PropTypes.exact({
  betStatus: PropTypes.objectOf(PropTypes.bool),
  betMinOdd: PropTypes.number,
  betMinStake: PropTypes.number,
  betMaxStake: PropTypes.number,
  bonusSum: PropTypes.arrayOf(
    PropTypes.exact({
      fiatAmount: PropTypes.number,
      cryptoAmount: PropTypes.number,
      quantity: PropTypes.number,
      totalBets: PropTypes.number.isRequired,
      customAmounts: PropTypes.objectOf(PropTypes.number),
    })
  ),
  configuration: PropTypes.shape({
    isFiatAmountActive: PropTypes.bool,
    isCryptoAmountActive: PropTypes.bool,
  }),
  eventType: BonusEventType,
  eventsDetails: PropTypes.arrayOf(EventConditionType),
  eventsCountries: PropTypes.arrayOf(PropTypes.string),
});

export const MediaType = PropTypes.exact({
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  name: PropTypes.string,
  extension: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
});

export const MediaRestrictionsType = PropTypes.exact({
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
});

const CommonBonusPropsType = {
  author: UserType.isRequired,
  createdAt: PropTypes.string.isRequired,
  displayName: DisplayNameType.isRequired,
  id: PropTypes.string.isRequired,
  issuePeriod: DatePeriodType.isRequired,
  name: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PermissionType).isRequired,
  status: PropTypes.string.isRequired,
  bonusType: BonusTypeType.isRequired,
  notifyByEmail: PropTypes.bool,
  banners: PropTypes.exact({
    isEnabled: PropTypes.bool.isRequired,
    desktop: MediaType,
    mobile: MediaType,
  }),
};

const HuntingTriggerFieldsType = {
  triggerName: PropTypes.string.isRequired,
  affiliateTag: PropTypes.string,
  playerId: PropTypes.arrayOf(PropTypes.number),
  tags: PropTypes.arrayOf(PropTypes.number),
  isTagsExcluded: PropTypes.bool,
  onlyVerified: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.string),
  daysAfterRegistration: PropTypes.number,
  depositNumber: PropTypes.number,
  depositSum: PropTypes.number,
  depositPeriod: DatePeriodType,
  promocode: PropTypes.string,
  promocodeQuantity: PropTypes.number,
  bonusSum: BonusSumType,
  depositBonusSum: DepositBonusSumType,
  afterMakingBets: AfterMakingBetsType,
};

const FullTriggerFieldsType = {
  ...HuntingTriggerFieldsType,
  afterMakingBets: AfterMakingBetsType,
};

export const ComboboostType = PropTypes.exact({
  ...CommonBonusPropsType,
  ...FullTriggerFieldsType,
  countryCodes: PropTypes.arrayOf(PropTypes.string),
  eventConditions: PropTypes.arrayOf(EventConditionType),
  minimumOutcomeOdd: PropTypes.number,
  minimumSelectionOdd: PropTypes.number,
  bonusOdds: PropTypes.arrayOf(bonusOddType).isRequired,
  validityPeriod: DatePeriodType.isRequired,
  quantity: PropTypes.number,
  showInPromotion: PropTypes.bool.isRequired,
});

export const FreebetType = PropTypes.exact({
  ...CommonBonusPropsType,
  ...FullTriggerFieldsType,
  freebetType: PropTypes.string,
  countryCodes: PropTypes.arrayOf(PropTypes.string),
  eventConditions: PropTypes.arrayOf(EventConditionType),
  minimumOdd: PropTypes.number.isRequired,
  maximumOdd: PropTypes.number,
  validityPeriod: DatePeriodType.isRequired,
  validDays: PropTypes.number,
  type: PropTypes.string,
  betType: PropTypes.string.isRequired,
  matchStatus: PropTypes.string,
  onlyTopMarkets: PropTypes.bool,
  selectionsFrom: PropTypes.number,
  boost: PropTypes.arrayOf(BoostItemType),
  hasChildren: PropTypes.bool,
  quantity: PropTypes.number,
});

export const HuntingType = PropTypes.exact({
  ...CommonBonusPropsType,
  huntingType: PropTypes.oneOf(Object.values(HUNTING_TYPES)),
  showInPromotion: PropTypes.bool.isRequired,
  minimumOutcomeOdd: PropTypes.number.isRequired,
  issuePoints: PropTypes.exact({
    win: PropTypes.number,
    lose: PropTypes.number,
  }).isRequired,
  issueForSameEvent: PropTypes.bool.isRequired,
  farmingCountryCodes: PropTypes.arrayOf(PropTypes.string),
  farmingEventConditions: PropTypes.arrayOf(EventConditionType),
  farmingDays: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  selectionsFrom: PropTypes.number.isRequired,
  minimumOdd: PropTypes.number.isRequired,
  maximumOdd: PropTypes.number,
  validityPeriod: DatePeriodType.isRequired,
  validDays: PropTypes.number,
  ...HuntingTriggerFieldsType,
});

export const HuntingLootboxType = PropTypes.exact({
  ...HuntingType,
  boost: PropTypes.arrayOf(BoostItemType),
});

export const HuntingTournamentType = PropTypes.exact({
  ...HuntingType,
  poolAmount: PropTypes.number.isRequired,
});

export const BonusType = PropTypes.oneOfType([
  ComboboostType,
  FreebetType,
  HuntingType,
  HuntingLootboxType,
  HuntingTournamentType,
]);

export const DayjsDateType = PropTypes.shape({
  format: PropTypes.func,
  add: PropTypes.func,
  startOf: PropTypes.func,
});

export const StatisticsMetricType = PropTypes.shape({
  id: PropTypes.number,
  activePlayers: PropTypes.number,
  arpu: PropTypes.number,
  avgBet: PropTypes.number,
  betcashoutPercentage: PropTypes.number,
  betcashoutReal: PropTypes.number,
  betcashoutResult: PropTypes.number,
  betsCount: PropTypes.number,
  betsSum: PropTypes.number,
  betType: PropTypes.string,
  bonusBetsSum: PropTypes.number,
  date: PropTypes.string,
  device: PropTypes.string,
  ggr: PropTypes.number,
  margin: PropTypes.number,
  newActivePlayers: PropTypes.number,
  pendingBetsSum: PropTypes.number,
  pendingBonusesBetsSum: PropTypes.number,
  potentialBonusWinSum: PropTypes.number,
  potentialWinSum: PropTypes.number,
  projectName: PropTypes.string,
  sportUrnId: PropTypes.string,
  uniqUsedBonusesUserCount: PropTypes.number,
  usedBonusesBetsCount: PropTypes.number,
  winningBonusesBetsCount: PropTypes.number,
  winningBonusesSum: PropTypes.number,
  jackpotWin: PropTypes.number,
});

export const ManagerType = PropTypes.exact({
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  lastName: PropTypes.string.isRequired,
  phone: PropTypes.string,
});

export const ProjectType = PropTypes.exact({
  key: PropTypes.string.isRequired,
  managers: PropTypes.arrayOf(ManagerType).isRequired,
  name: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PermissionType).isRequired,
  feeGroups: PropTypes.arrayOf(PropTypes.string),
});

export const DraftType = PropTypes.exact({
  id: PropTypes.number.isRequired,
  authorId: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PermissionType).isRequired,
  project: ProjectType.isRequired,
  type: BonusTypeType.isRequired,
  updatedAt: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
});

const SectionType = PropTypes.exact({
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PermissionType).isRequired,
});

export const RoleType = PropTypes.exact({
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  parentKey: PropTypes.string,
  permissions: PropTypes.arrayOf(PermissionType).isRequired,
  sections: PropTypes.arrayOf(SectionType),
});

export const ConflictRoleType = PropTypes.exact({
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(UserType).isRequired,
});

export const CountryType = PropTypes.exact({
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
});

export const LocaleType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  default: PropTypes.bool,
  englishName: PropTypes.string.isRequired,
  elasticAnalyzer: PropTypes.string.isRequired,
  nativeName: PropTypes.string.isRequired,
});

export const RefType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({
    current: PropTypes.instanceOf(typeof Element === 'undefined' ? Function : Element),
  }),
]);

export const JackpotHitType = PropTypes.exact({
  amount: PropTypes.number.isRequired,
  betId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  currencySubunits: PropTypes.number.isRequired,
  hitId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  platformId: PropTypes.number.isRequired,
  sportsbookId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
});

export const FeaturesType = PropTypes.objectOf(PropTypes.bool);

export const JackpotTeamType = PropTypes.exact({
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  authorId: PropTypes.number.isRequired,
  betType: PropTypes.string.isRequired,
  matchStatus: PropTypes.string.isRequired,
  onlyTopMarkets: PropTypes.bool.isRequired,
  minimumOdd: PropTypes.number,
  maximumOdd: PropTypes.number,
  minStake: PropTypes.number,
  maxStake: PropTypes.number,
  createdAt: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(EventConditionType),
  filterType: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.number),
  isTagsExcluded: PropTypes.bool.isRequired,
});

export const CurrencyType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  isFiat: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  subunits: PropTypes.number.isRequired,
});

export const ProfileGroupType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

export const ProfileBalanceType = PropTypes.exact({
  totalEur: PropTypes.number.isRequired,
  details: PropTypes.arrayOf(
    PropTypes.exact({
      amount: PropTypes.number.isRequired,
      amountEur: PropTypes.number.isRequired,
      currency: PropTypes.exact({
        code: PropTypes.string.isRequired,
        subunits: PropTypes.number.isRequired,
      }),
    })
  ),
});

export const ProfileType = PropTypes.shape({
  affiliateTag: PropTypes.string,
  balance: ProfileBalanceType.isRequired,
  birthDate: PropTypes.string,
  countryCode: PropTypes.string,
  email: PropTypes.string.isRequired,
  gender: PropTypes.string,
  globalId: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(ProfileGroupType).isRequired,
  hierarchy: PropTypes.arrayOf(PropTypes.string),
  mergedId: PropTypes.string,
  platformId: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  registeredAt: PropTypes.string.isRequired,
  selectedCurrency: PropTypes.string.isRequired,
  selectedLanguage: PropTypes.string,
  sportsbookId: PropTypes.number.isRequired,
  verified: PropTypes.bool.isRequired,
  ccf: PropTypes.number.isRequired,
  ltd: PropTypes.number.isRequired,
  mergedBy: PropTypes.string,
});

export const AvgLineType = PropTypes.exact({
  bookmakers: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      odds: PropTypes.number,
    })
  ),
  value: PropTypes.number,
  valuedatorResult: ValuedatorResultType,
});

const BetSelectionType = PropTypes.exact({
  id: PropTypes.string.isRequired,
  odds: PropTypes.number.isRequired,
  outcomeName: PropTypes.string.isRequired,
  sportKey: PropTypes.string.isRequired,
  sportName: PropTypes.string.isRequired,
  sportId: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  tournamentName: PropTypes.string.isRequired,
  tournamentId: PropTypes.string.isRequired,
  marketName: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  score: PropTypes.exact({
    home: PropTypes.number.isRequired,
    away: PropTypes.number.isRequired,
  }),
  producer: ProducerType.isRequired,
  eventName: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  status: BetSelectionStatusType.isRequired,
  avgLine: AvgLineType,
});

export const SportsbookBetType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: BetTypeType.isRequired,
  odds: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  settledAt: PropTypes.string,
  isVip: PropTypes.bool.isRequired,
  selections: PropTypes.arrayOf(BetSelectionType),
  currency: PropTypes.string.isRequired,
  stake: PropTypes.number.isRequired,
  stakeEur: PropTypes.number.isRequired,
  potentialWin: PropTypes.number,
  potentialWinEur: PropTypes.number.isRequired,
  potentialBonusWin: PropTypes.number,
  potentialBonusWinEur: PropTypes.number,
  playerPlatformId: PropTypes.string.isRequired,
  playerSportsBookId: PropTypes.string.isRequired,
  playerEmail: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  ip: PropTypes.string.isRequired,
  status: BetStatusType.isRequired,
  isManuallyProcessing: PropTypes.bool.isRequired,
  result: BetResultType.isRequired,
  systemInfo: PropTypes.exact({
    winCount: PropTypes.number,
    totalCount: PropTypes.number,
  }),
  betSource: BetSourceType.isRequired,
  win: PropTypes.number,
  winEur: PropTypes.number,
  bonusWin: PropTypes.number,
  bonusWinEur: PropTypes.number,
  profit: PropTypes.number,
  profitEur: PropTypes.number,
  bonusId: PropTypes.string,
  bonusType: PropTypes.string,
  bonusOdds: PropTypes.number,
  jackpots: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  valuedatorResult: ValuedatorResultType,
});

const TurfsportBetSelectionType = {
  id: PropTypes.string.isRequired,
  odds: PropTypes.number.isRequired,
  outcomeName: PropTypes.string.isRequired,
  sportKey: PropTypes.string.isRequired,
  sportName: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  tournamentName: PropTypes.string.isRequired,
  marketName: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  producer: ProducerType.isRequired,
  eventName: PropTypes.string.isRequired,
  result: TursportBetResultType.isRequired,
};

export const TurfsportBetType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  ticketId: PropTypes.string.isRequired,
  type: BetTypeType.isRequired,
  odds: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  settledAt: PropTypes.string,
  selections: PropTypes.arrayOf(TurfsportBetSelectionType),
  currency: PropTypes.string.isRequired,
  stake: PropTypes.number.isRequired,
  stakeEur: PropTypes.number.isRequired,
  potentialWin: PropTypes.number,
  potentialWinEur: PropTypes.number.isRequired,
  playerPlatformId: PropTypes.string.isRequired,
  playerSportsBookId: PropTypes.string.isRequired,
  playerEmail: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  ip: PropTypes.string.isRequired,
  result: TursportBetResultType.isRequired,
  betSource: BetSourceType.isRequired,
  win: PropTypes.number,
  winEur: PropTypes.number,
  profit: PropTypes.number,
  profitEur: PropTypes.number,
  tax: PropTypes.number,
});

export const BetType = PropTypes.oneOfType([SportsbookBetType, TurfsportBetType]);

export const PaymentMethodMetricType = PropTypes.shape({
  paymentMethod: PropTypes.string.isRequired,
  depositsCount: PropTypes.number.isRequired,
  depositsSumEur: PropTypes.number.isRequired,
  successCashoutsSumEur: PropTypes.number.isRequired,
  pendingCashoutsSumEur: PropTypes.number.isRequired,
});

export const PaymentMethodByCurrency = PropTypes.shape({
  paymentMethod: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  depositsCount: PropTypes.number.isRequired,
  depositsSum: PropTypes.number.isRequired,
  depositsSumEur: PropTypes.number.isRequired,
});

export const BonusStatisticsType = PropTypes.shape({
  id: PropTypes.number,
  bonusType: PropTypes.string,
  bonusIssued: PropTypes.number,
  bonusUsed: PropTypes.number,
  bonusConversion: PropTypes.number,
  turnover: PropTypes.number,
  ggr: PropTypes.number,
  winnings: PropTypes.number,
  margin: PropTypes.number,
});

export const ChildrenType = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.arrayOf(PropTypes.element),
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.string,
]);

export const EventType = PropTypes.exact({
  eventId: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  sport: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  tournament: PropTypes.string.isRequired,
  firstIssuedBetTime: PropTypes.string,
  firstBetId: PropTypes.string,
  lastIssuedBetTime: PropTypes.string,
  lastBetId: PropTypes.string,
  noMoreBetsTime: PropTypes.string,
  resultProcessed: PropTypes.string,
  revisedOfficialCardedTime: PropTypes.string,
  eventChangesReason: PropTypes.string,
});

export const ReportType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  expiredAt: PropTypes.string,
  status: PropTypes.oneOf(Object.values(REPORT_STATUSES)).isRequired,
});

export const GtmEventPageType = PropTypes.oneOf(Object.values(GTM_EVENT_PAGES));

export const GtmEventCategoryType = PropTypes.oneOf(Object.values(GTM_EVENT_CATEGORIES));

export const DataProviderType = PropTypes.oneOf(Object.values(DATA_PROVIDERS));
