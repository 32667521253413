import dayjs from 'dayjs';

import {
  BET_TYPES,
  BITCOIN,
  BONUS_EVENTS_TYPES,
  BONUS_FIELDS,
  BONUS_TYPES,
  DEPOSIT_AUDIENCE_TYPES,
  DEPOSIT_SUM_TYPES,
  EURO,
  EVENT_STATUS_TYPES,
  RULE_NAMES,
  SUBUNITS,
  TEAM_FIELDS,
  TRIGGER_NAMES,
  VALIDITY_TYPES,
} from 'utils/constants';
import { multiplyValues } from 'utils/numbers';
import { omitEmptyValues } from 'utils/object';

export function serializeUser(user) {
  return {
    first_name: user.firstName,
    last_name: user.lastName,
    department: user.department,
    job_title: user.jobTitle,
    phone_number: user.phone,
    email: user.email,
    additional_project: user.projects,
    role: user.roles,
    tz: user.tz,
  };
}

export function serializeRole(role) {
  return {
    name: role.name,
    parent_role: role.parentKey,
    sections: role.sections,
  };
}

const serializeBonusOdds = b =>
  b.map((o, i) => ({
    odds: o.odd,
    outcomes_count: {
      min: o.from,
      max: b[i + 1] ? b[i + 1].from - 1 : undefined,
    },
  }));

const serializeEventConditions = (conditions = []) => {
  return conditions.map(condition => ({
    sport_type: condition.sportType,
    sport_id: condition.sportId,
    category_id: condition.categoryId,
    tournament_id: condition.tournamentId,
    event_id: condition.eventId,
  }));
};

const serializeCryptoAmounts = bonus => {
  if (bonus.depositBonusSum?.isCryptoActive) {
    return bonus.depositBonusSum.cryptoAmounts.BTC.values.map((item, index, self) => ({
      from: item.from,
      to: index === self.length - 1 ? undefined : self[index + 1].from - 1,
      type: bonus.depositBonusSum?.type,
      value: item.value,
      max: bonus.depositBonusSum.cryptoAmounts.BTC.max,
      custom_amounts: { ...item.convertedValues?.fiat, ...item.convertedValues?.crypto },
    }));
  }

  return [];
};

const serializeFiatAmounts = bonus => {
  if (bonus?.depositBonusSum?.fiatAmounts) {
    return bonus.depositBonusSum.fiatAmounts.EUR.values.map((item, index, self) => ({
      from: item.from,
      to: index === self.length - 1 ? undefined : self[index + 1].from - 1,
      type: bonus.depositBonusSum.type,
      value: item.value,
      max: bonus.depositBonusSum.fiatAmounts.EUR.max,
      custom_amounts: { ...item.convertedValues?.fiat, ...item.convertedValues?.crypto },
    }));
  }

  return [];
};

const serializeFormAmounts = (amounts, defaultCurrency, isWithMax) => {
  if (!amounts) return undefined;

  return Object.keys(amounts).reduce(
    (acc, nextItem) =>
      nextItem === defaultCurrency || amounts[nextItem].isSelected
        ? {
            ...acc,
            [nextItem]: {
              ...(isWithMax && { max: amounts[nextItem].max }),
              values: amounts[nextItem].values,
            },
          }
        : acc,
    {}
  );
};

const serializeAmounts = bonus => {
  const isWithMax = bonus.depositBonusSum.type === DEPOSIT_SUM_TYPES.INDIVIDUAL;
  const fiatAmounts = serializeFormAmounts(bonus.depositBonusSum.fiatAmounts, EURO.code, isWithMax);
  const cryptoAmounts = serializeFormAmounts(
    bonus.depositBonusSum.cryptoAmounts,
    BITCOIN.code,
    isWithMax
  );

  return {
    type: bonus.depositBonusSum.type,
    fiat: fiatAmounts,
    ...(bonus.depositBonusSum.isCryptoActive &&
      cryptoAmounts &&
      Boolean(Object.keys(cryptoAmounts).length) && { crypto: cryptoAmounts }),
  };
};

const serializeSelections = bonus => ({
  selections_count_from: bonus.betType === BET_TYPES.COMBO ? bonus.selectionsFrom : 1,
  selections_count_to: bonus.betType === BET_TYPES.SINGLE ? 1 : undefined,
});

const serializePeriodFromDate = datePeriod => {
  return datePeriod.isOnSubmit ? undefined : datePeriod.from;
};

const serializeValidityPeriod = bonus => {
  if (bonus.validityType === VALIDITY_TYPES.INDIVIDUAL) {
    return {
      valid_after_create: bonus.issuePeriod.isOnSubmit,
      valid_from: serializePeriodFromDate(bonus.issuePeriod),
      valid_days: bonus.validDays,
    };
  }

  return {
    valid_after_create: bonus.validityPeriod.isOnSubmit,
    valid_from: serializePeriodFromDate(bonus.validityPeriod),
    valid_to: bonus.validityPeriod?.to || undefined,
  };
};

const serializeOnDepositTriggerFields = bonus => {
  const triggerFields = [];

  const audienceType = bonus[BONUS_FIELDS.AUDIENCE_TYPE];
  if (audienceType === DEPOSIT_AUDIENCE_TYPES.ALL) {
    triggerFields.push(
      {
        name: bonus[BONUS_FIELDS.IS_TAGS_EXCLUDED] ? 'except_tag_id' : 'tag_id',
        value: bonus[BONUS_FIELDS.TAGS],
      },
      {
        name: bonus.isDepositNumberAfterRegistration
          ? 'only_deposit_number_after_registration'
          : 'only_deposit_number',
        value: bonus[BONUS_FIELDS.DEPOSIT_NUMBER] ?? undefined,
      }
    );
  } else if (audienceType === DEPOSIT_AUDIENCE_TYPES.NEW_PLAYER) {
    const daysAfterRegistration = bonus[BONUS_FIELDS.DAYS_AFTER_REGISTRATION];
    const issuePeriod = bonus[BONUS_FIELDS.ISSUE_PERIOD];
    triggerFields.push(
      {
        name: 'register_from',
        value: daysAfterRegistration > 0 ? issuePeriod?.from || new Date() : undefined,
      },
      {
        name: 'register_to',
        value: daysAfterRegistration > 0 ? issuePeriod?.to || new Date() : undefined,
      },
      {
        name: 'deposit_days_after_registration',
        value: daysAfterRegistration,
      },
      { name: 'only_deposit_number', value: bonus[BONUS_FIELDS.DEPOSIT_NUMBER] ?? undefined }
    );
  }

  if (bonus.depositBonusSum.autoconvert) {
    triggerFields.push(
      { name: 'crypto_amounts', value: serializeCryptoAmounts(bonus) },
      { name: 'fiat_amounts', value: serializeFiatAmounts(bonus) }
    );
  } else {
    triggerFields.push({ name: 'amounts_by_currency', value: serializeAmounts(bonus) });
  }

  return triggerFields;
};

function serializeCountryCodes(countryCodes) {
  return countryCodes?.length > 0 ? countryCodes : [];
}

const serializeTriggerField = bonus => {
  const triggerFields = [];

  if (bonus.triggerName === TRIGGER_NAMES.NO_ISSUANCE_EVENT) {
    triggerFields.push({ name: 'player_id', value: bonus.playerId.map(Number) });
  }

  if (bonus.triggerName === TRIGGER_NAMES.ON_REGISTRATION) {
    triggerFields.push({ name: 'affiliate_tag', value: bonus.affiliateTag });
  }

  if (
    [
      TRIGGER_NAMES.STAYING_IN_GROUP,
      TRIGGER_NAMES.ENTERING_IN_GROUP,
      TRIGGER_NAMES.STAYING_ENTERING_IN_GROUP,
      TRIGGER_NAMES.ON_ENTER_PROMOCODE,
      TRIGGER_NAMES.ON_BIRTHDAY,
      TRIGGER_NAMES.AFTER_MAKING_BETS,
    ].includes(bonus.triggerName)
  ) {
    triggerFields.push({
      name: bonus[BONUS_FIELDS.IS_TAGS_EXCLUDED] ? 'except_tag_id' : 'tag_id',
      value: bonus[BONUS_FIELDS.TAGS],
    });
  }

  if (
    [
      TRIGGER_NAMES.STAYING_IN_GROUP,
      TRIGGER_NAMES.ENTERING_IN_GROUP,
      TRIGGER_NAMES.STAYING_ENTERING_IN_GROUP,
      TRIGGER_NAMES.ON_ENTER_PROMOCODE,
      TRIGGER_NAMES.ON_DEPOSIT,
      TRIGGER_NAMES.ON_BIRTHDAY,
      TRIGGER_NAMES.AFTER_MAKING_BETS,
    ].includes(bonus.triggerName)
  ) {
    triggerFields.push(
      { name: 'only_verified', value: bonus.onlyVerified },
      { name: 'countries', value: bonus.onlyVerified ? bonus.countries : [] },
      { name: 'deposit_sum', value: bonus.isDepositActive ? bonus.depositSum : undefined },
      {
        name: 'deposit_from',
        value: bonus.isDepositActive ? bonus.depositPeriod?.from : undefined,
      },
      { name: 'deposit_to', value: bonus.isDepositActive ? bonus.depositPeriod?.to : undefined }
    );
  }

  if (bonus.triggerName === TRIGGER_NAMES.ON_DEPOSIT) {
    triggerFields.push(...serializeOnDepositTriggerFields(bonus));
  }

  if ([TRIGGER_NAMES.ON_ENTER_PROMOCODE, TRIGGER_NAMES.ON_DEPOSIT].includes(bonus.triggerName)) {
    triggerFields.push({ name: 'bonus_code', value: bonus.promocode });
  }

  if (
    bonus.promocodeQuantity > 0 &&
    (bonus.triggerName === TRIGGER_NAMES.ON_ENTER_PROMOCODE ||
      (bonus.triggerName === TRIGGER_NAMES.ON_DEPOSIT &&
        bonus.isPromocodeLimited === true &&
        bonus.promocode))
  ) {
    triggerFields.push({ name: 'bonus_code_quantity', value: bonus.promocodeQuantity });
  }

  if (bonus.triggerName === TRIGGER_NAMES.AFTER_MAKING_BETS) {
    const {
      betStatus,
      betMinOdd,
      betMinStake,
      betMaxStake,
      bonusSum,
      configuration,
      eventType,
      eventsDetails,
      eventsCountries,
      onlyTopMarkets,
      matchStatus,
      betType,
    } = bonus.afterMakingBets;

    triggerFields.push(
      { name: 'event_status', value: matchStatus },
      { name: 'only_top_markets', value: onlyTopMarkets },
      {
        name: 'bet_types',
        value: {
          types: Object.entries(betType).reduce(
            (acc, [key, value]) => (value === true ? [...acc, key] : acc),
            []
          ),
          multi_min_outcomes: betType.multi ? betType.multiValue : undefined,
          system_min_selections: betType.system ? betType.systemValue : undefined,
        },
      },
      {
        name: 'bet_status',
        value: Object.keys(betStatus).filter(status => betStatus[status]),
      },
      { name: 'bet_min_odds', value: betMinOdd },
      { name: 'bet_min_stake', value: betMinStake },
      { name: 'bet_max_stake', value: betMaxStake || undefined },
      {
        name: 'bet_ranges',
        value: bonusSum.map(
          ({ fiatAmount, cryptoAmount, quantity, totalBets, convertedValues }) => ({
            bonus_fiat_amount: configuration.isFiatAmountActive ? fiatAmount : undefined,
            bonus_crypto_amount: configuration.isCryptoAmountActive ? cryptoAmount : undefined,
            uses_count: quantity,
            total_bets: totalBets,
            custom_amounts: { ...convertedValues?.fiat, ...convertedValues?.crypto },
          })
        ),
      },
      eventType === BONUS_EVENTS_TYPES.EVENTS
        ? {
            name: 'events_details',
            value: serializeEventConditions(eventsDetails),
          }
        : {
            name: 'events_countries',
            value: serializeCountryCodes(eventsCountries),
          }
    );
  }

  return triggerFields.filter(trigger =>
    Array.isArray(trigger.value)
      ? trigger.value.length > 0
      : trigger.value !== undefined && trigger.value !== ''
  );
};

function serializeMedia(media) {
  return (
    media && {
      id: media.id,
      url: media.url,
      alt: media.alternativeText,
      name: media.name,
    }
  );
}

function serializeBonusSumFiatAmount(bonusSum) {
  return bonusSum?.isFiatAmountActive ? bonusSum?.fiatAmount : undefined;
}

function serializeBonusSumCryptoAmount(bonusSum) {
  return bonusSum?.isCryptoAmountActive ? bonusSum?.cryptoAmount : undefined;
}

function serializeBonusCustomAmounts(bonus) {
  return { ...bonus.convertedValues?.fiat, ...bonus.convertedValues?.crypto };
}

const serializeBonusCommonData = bonus => ({
  country_codes: serializeCountryCodes(bonus.countryCodes),
  display_name: bonus.displayName,
  event_conditions: serializeEventConditions(bonus.eventConditions),
  issue_after_create: bonus.issuePeriod.isOnSubmit,
  issue_from: serializePeriodFromDate(bonus.issuePeriod),
  issue_to: bonus.issuePeriod.to,
  name: bonus.name,
  trigger_fields: serializeTriggerField(bonus),
  trigger_name: bonus.triggerName || undefined,
  uses_count: bonus.quantity || undefined,
  notify_by_email: bonus.notifyByEmail,
  bonus_custom_amounts: serializeBonusCustomAmounts(bonus),
  banners: bonus.banners?.isEnabled
    ? {
        desktop: serializeMedia(bonus.banners.desktop),
        mobile: serializeMedia(bonus.banners.mobile),
      }
    : undefined,
});

const serializeComboboost = comboboost => ({
  ...serializeBonusCommonData(comboboost),
  ...serializeValidityPeriod(comboboost),
  min_selection_odds: comboboost.isMinimumSelectionOddActive
    ? comboboost.minimumSelectionOdd
    : undefined,
  min_odds: comboboost.isMinimumOddActive ? comboboost.minimumOutcomeOdd : undefined,
  bonus_odds: serializeBonusOdds(comboboost.bonusOdds),
  match_status:
    comboboost.matchStatus === EVENT_STATUS_TYPES.ALL ? undefined : comboboost.matchStatus,
  only_top_markets: comboboost.onlyTopMarkets,
  show_in_promotion: comboboost.showInPromotion,
});

function serializeFreebet(freebet) {
  return {
    ...serializeBonusCommonData(freebet),
    type: freebet.freebetType,
    min_odds: freebet.minimumOdd,
    max_odds: freebet.maximumOdd,
    ...serializeSelections(freebet),
    match_status: freebet.matchStatus === EVENT_STATUS_TYPES.ALL ? undefined : freebet.matchStatus,
    only_top_markets: freebet.onlyTopMarkets,
    ...serializeValidityPeriod(freebet),
    boost_data: freebet.isBoostActive ? freebet.boost : undefined,
    bonus_fiat_amount: serializeBonusSumFiatAmount(freebet.bonusSum),
    bonus_crypto_amount: serializeBonusSumCryptoAmount(freebet.bonusSum),
  };
}

function serializeHuntingBonusCustomAmounts(hunting) {
  return hunting.triggerName === TRIGGER_NAMES.ON_DEPOSIT
    ? {}
    : serializeBonusCustomAmounts(hunting);
}

function serializeHunting(hunting) {
  return {
    ...serializeBonusCommonData(hunting),
    show_in_promotion: hunting.showInPromotion,
    min_odds: hunting.minimumOutcomeOdd,
    farming_percent: hunting.issuePoints,
    farm_once_per_event: !hunting.issueForSameEvent,
    farming_event_conditions: serializeEventConditions(hunting.farmingEventConditions),
    farming_country_codes: serializeCountryCodes(hunting.farmingCountryCodes),
    farming_days: hunting.farmingDays,
    freebet: {
      type: hunting.freebetType,
      ...serializeSelections(hunting),
      min_odds: hunting.minimumOdd,
      max_odds: hunting.maximumOdd,
      match_status:
        hunting.matchStatus === EVENT_STATUS_TYPES.ALL ? undefined : hunting.matchStatus,
      only_top_markets: hunting.onlyTopMarkets,
      ...serializeValidityPeriod(hunting),
      country_codes: hunting.countryCodes?.length > 0 ? hunting.countryCodes : [],
      event_conditions: serializeEventConditions(hunting.eventConditions),
      boost_data: hunting.isBoostActive ? hunting.boost : undefined,
    },
    type: hunting.huntingType,
    bonus_fiat_amount: serializeBonusSumFiatAmount(hunting.bonusSum),
    bonus_crypto_amount: serializeBonusSumCryptoAmount(hunting.bonusSum),
    bonus_custom_amounts: serializeHuntingBonusCustomAmounts(hunting),
  };
}

export const serializeBonus = (bonusType, bonus) => {
  if (bonusType === BONUS_TYPES.COMBOBOOST) {
    return serializeComboboost(bonus);
  }

  if (bonusType === BONUS_TYPES.FREEBET) {
    return serializeFreebet(bonus);
  }

  if (bonusType === BONUS_TYPES.HUNTING) {
    return serializeHunting(bonus);
  }

  if (bonusType === BONUS_TYPES.LOOTBOX) {
    const result = serializeHunting(bonus);

    result.freebet.boost_data = bonus.lootboxBoost;

    return result;
  }

  if (bonusType === BONUS_TYPES.TOURNAMENT) {
    const result = serializeHunting(bonus);

    result.initial_pool = bonus.poolAmount;

    return result;
  }

  throw new Error(`Unexpected bonus type for serialization: ${bonusType}`);
};

export function serializeDraft(draft, bonusType) {
  return {
    name: draft.name,
    type: bonusType,
    data: draft,
  };
}

export function serializeProjectSettings(projectSettings) {
  return omitEmptyValues({
    sport_type_by_provider: {
      regular: projectSettings.regularProvider === '' ? null : projectSettings.regularProvider,
      cyber: projectSettings.esportProvider === '' ? null : projectSettings.esportProvider,
    },
    additional_providers: {
      horse_racing: {
        enabled: projectSettings.isHorseRacingEnabled,
      },
      lotto: {
        enabled: projectSettings.isLotteryEnabled,
      },
    },
    video_settings: {
      enabled: projectSettings.isVideoEnabled,
      providers: projectSettings.videoProviders,
    },
    valuedator_settings: {
      enabled: projectSettings.isValuedatorEnabled,
    },
    vip_bet_settings: {
      enabled: projectSettings.isVipBetEnabled,
      min_stake_eur: projectSettings.vipMinStake,
      min_potential_win_eur: projectSettings.vipMinPotentialWin,
      auto_reject: {
        enabled: projectSettings.isVipBetAutoRejectEnabled,
        live: projectSettings.vipBetAutoRejectLiveSeconds,
        prematch: projectSettings.vipBetAutoRejectPrematchSeconds,
      },
    },
    multi_bet_settings: {
      available_by_provider: projectSettings.multiBetsAvailableByProvider,
    },
  });
}

function serializeJackpotTeamFilterFields(data) {
  return {
    ...(data[TEAM_FIELDS.RULE_NAME] === RULE_NAMES.PROMOCODE && {
      code: data[TEAM_FIELDS.PROMOCODE],
    }),
    tag_id: data[TEAM_FIELDS.TAGS],
    exclude: data[TEAM_FIELDS.IS_TAGS_EXCLUDED],
  };
}

export function serializeJackpotTeam(data) {
  return {
    filter: {
      type: data[TEAM_FIELDS.RULE_NAME],
      fields: serializeJackpotTeamFilterFields(data),
    },
    bet: {
      bet_type: data[TEAM_FIELDS.BET_TYPE],
      min_stake: data[TEAM_FIELDS.STAKE_MIN],
      max_stake: data[TEAM_FIELDS.STAKE_MAX],
      min_odds: data[TEAM_FIELDS.MINIMUM_ODD],
      max_odds: data[TEAM_FIELDS.MAXIMUM_ODD],
      match_status: data[TEAM_FIELDS.MATCH_STATUS],
      only_top_markets: data[TEAM_FIELDS.ONLY_TOP_MARKETS],
    },
    events: serializeEventConditions(data[TEAM_FIELDS.EVENT_CONDITIONS]),
    name: data[TEAM_FIELDS.NAME],
  };
}

export function serializeMergePlayers(data) {
  return {
    merge_to: data.mergeTo,
    player_ids: data.playerIds,
  };
}

export function serializeDetachPlayers(data) {
  return {
    primary_player_id: data.primaryPlayerId,
    secondary_player_id: data.secondaryPlayerId,
  };
}

export function serializeUserSettingsMinStake(data) {
  return Object.fromEntries(Object.entries(data).filter(([, value]) => value));
}

function serializeDate(date) {
  if (!date) return date;
  return dayjs(date).toISOString();
}

export function serializeDateFrom(date, timezone) {
  return serializeDate(date && dayjs(date).tz(timezone).startOf('day'));
}

export function serializeDateTo(date, timezone) {
  return serializeDate(date && dayjs(date).tz(timezone).endOf('day'));
}

export function serializeExcludedDateTo(date, timezone) {
  return serializeDate(date && dayjs(date).tz(timezone).add(1, 'day').startOf('day'));
}

export function serializeCcf({ payload, profiles }) {
  return profiles.map(profile => ({
    auto_update: profiles.length === 1 ? payload.autoUpdate : undefined,
    auto_update_previous: profile.ccfAutoUpdate,
    comment: payload.comment,
    global_id: profile.globalId,
    value: multiplyValues([payload.value, 100]),
    value_previous: multiplyValues([profile.ccf, 100]),
  }));
}

export function serializeLtd({ payload, profiles }) {
  return profiles.map(profile => ({
    auto_update: profiles.length === 1 ? payload.autoUpdate : undefined,
    auto_update_previous: profile.ltdAutoUpdate,
    comment: payload.comment,
    global_id: profile.globalId,
    value: payload.value,
    value_previous: profile.ltd,
  }));
}

export function serializeBetsStatisticsParams({ params, timezone }) {
  const { players, period, sportExternalIds, tournamentExternalIds, categoryExternalIds } = params;

  const idsPerProject = players.reduce(
    (acc, player) => ({
      ...acc,
      [player.project]: [...(acc[player.project] || []), player.sportsbookId],
    }),
    {}
  );

  const customerGlobalAccounts = Object.entries(idsPerProject).map(([playerProjectKey, ids]) => ({
    client_name: playerProjectKey,
    customer_ids: ids,
  }));

  return {
    customer_global_accounts: customerGlobalAccounts,
    timezone,
    from: serializeDateFrom(period?.[0], timezone),
    to: serializeExcludedDateTo(period?.[1], timezone),
    sport_external_ids: sportExternalIds?.map(String),
    tournament_external_ids: tournamentExternalIds?.map(String),
    category_external_ids: categoryExternalIds?.map(String),
  };
}

function serializeDenomination(value) {
  return typeof value === 'number' && value >= 0 ? value * SUBUNITS.CURRENCY : undefined;
}

export function serializerBetsFilters(params, timezone) {
  const {
    page,
    limit,
    isOnlyVip,
    isManuallyProcessed,
    term,
    sortBy,
    sportUrnId,
    categoryUrnId,
    tournamentUrnId,
    eventUrnId,
    type,
    status,
    result,
    stakeEurFrom,
    stakeEurTo,
    winEurFrom,
    winEurTo,
    placementPeriod,
    settlementPeriod,
    betSource,
    valuedatorResult,
    valuedatorValueFrom,
    valuedatorValueTo,
    bonusType,
  } = params;

  return {
    page,
    limit,
    only_vip: isOnlyVip || undefined,
    manually_processed: isManuallyProcessed || undefined,
    search_term: term,
    sort_by: sortBy,
    created_from: serializeDateFrom(placementPeriod?.[0], timezone),
    created_to: serializeDateTo(placementPeriod?.[1], timezone),
    settled_from: serializeDateFrom(settlementPeriod?.[0], timezone),
    settled_to: serializeDateTo(settlementPeriod?.[1], timezone),
    sport_urn_id: sportUrnId,
    category_urn_id: categoryUrnId,
    tournament_urn_id: tournamentUrnId,
    event_urn_id: eventUrnId,
    stake_eur_from: serializeDenomination(stakeEurFrom),
    stake_eur_to: serializeDenomination(stakeEurTo),
    win_eur_from: serializeDenomination(winEurFrom),
    win_eur_to: serializeDenomination(winEurTo),
    bonus_type: bonusType,
    status,
    result,
    type,
    bet_source: betSource,
    valuedator_result: valuedatorResult,
    valuedator_ev_from: typeof valuedatorValueFrom === 'number' ? valuedatorValueFrom : undefined,
    valuedator_ev_to: typeof valuedatorValueTo === 'number' ? valuedatorValueTo : undefined,
  };
}

export function serializeTurfsportBetsFilters(params, timezone) {
  const {
    page,
    limit,
    term,
    sortBy,
    result,
    type,
    stakeEurFrom,
    stakeEurTo,
    winEurFrom,
    winEurTo,
    placementPeriod,
    settlementPeriod,
    betSource,
    sportUrnId,
    categoryUrnId,
    tournamentUrnId,
    eventUrnId,
  } = params;

  return {
    page,
    limit,
    search_term: term,
    sort_by: sortBy,
    tournament_urn_ids: tournamentUrnId,
    sport_urn_ids: sportUrnId,
    category_urn_ids: categoryUrnId,
    event_urn_ids: eventUrnId,
    created_from: serializeDateFrom(placementPeriod?.[0], timezone),
    created_to: serializeDateTo(placementPeriod?.[1], timezone),
    settled_from: serializeDateFrom(settlementPeriod?.[0], timezone),
    settled_to: serializeDateTo(settlementPeriod?.[1], timezone),
    stake_eur_from: serializeDenomination(stakeEurFrom),
    stake_eur_to: serializeDenomination(stakeEurTo),
    win_eur_from: serializeDenomination(winEurFrom),
    win_eur_to: serializeDenomination(winEurTo),
    result,
    type,
    bet_source: betSource,
  };
}

export function serializeEventsFilters(params, timezone) {
  const { startPeriod, processedPeriod, page, limit, term } = params;

  return {
    page,
    limit,
    term,
    start_from: serializeDateFrom(startPeriod?.[0], timezone),
    start_to: serializeDateTo(startPeriod?.[1], timezone),
    result_processed_from: serializeDateFrom(processedPeriod?.[0], timezone),
    result_processed_to: serializeDateTo(processedPeriod?.[1], timezone),
  };
}

const GROUP_CLIENT_NAME_REGEX = /^(\d+)(?: \((.+)\))?$/;

export function serializeCustomerGroup(group) {
  const match = group.match(GROUP_CLIENT_NAME_REGEX);

  return match
    ? {
        client_name: match[2] ?? null,
        ids: [Number(match[1])],
      }
    : null;
}
